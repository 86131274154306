import { get, filter, isEqual, isNumber } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import {
  AUTHOR_INFO_TYPE_MOBILE_PATH,
  AUTHOR_INFO_TYPE_NAME_PICTURE,
  AUTHOR_INFO_TYPE_PATH,
  CARD_BORDER_WIDTH_PATH,
  getGenericPostFontPath,
  getHeaderTextAlignmentPath,
  getIsSocialSharingProviderEnabledParamPath,
  getPostFontSizesPath,
  getSBSLayoutImagePositionParamPath,
  getLayoutPostSizeParamPath,
  getLayoutSpacingParamPath,
  getLayoutContentHeightParamPath,
  getLayoutImageRatioParamPath,
  getLayoutSidesPaddingParamPath,
  getLayoutMarginsParamPath,
  getLayoutImageProportionsParamPath,
  getLayoutImageAlignmentParamPath,
  getFeedBorderColorPath,
  HEADER_DEPRECTED_PATH,
  HEADER_SHOW_ON_BLOG_HOMEPAGE_PATH,
  HEADER_SHOW_ON_CATEGORY_PAGE_PATH,
  IS_AUTHOR_NAME_ENABLED_MOBILE_PATH,
  IS_AUTHOR_NAME_ENABLED_PATH,
  IS_BANNER_ENABLED_PATH,
  IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH,
  IS_BLOG_MENU_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_ENABLED_PATH,
  IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH,
  IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_SEARCH_ENABLED_PATH,
  IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
  IS_CATEGORY_LABELS_ENABLED_PATH,
  IS_COMMENT_COUNT_ENABLED_MOBILE_PATH,
  IS_COMMENT_COUNT_ENABLED_PATH,
  IS_COMMENTS_ENABLED_MOBILE_PATH,
  IS_COMMENTS_ENABLED_PATH,
  IS_FEED_DESIGN_PROPS_MIGRATED_PATH,
  IS_LIKE_COUNT_ENABLED_MOBILE_PATH,
  IS_LIKE_COUNT_ENABLED_PATH,
  IS_MOBILE_DIPSLAY_SETTINGS_ENABLED_PATH,
  IS_PINTEREST_ENABLED_PATH,
  IS_POST_DATE_ENABLED_MOBILE_PATH,
  IS_POST_DATE_ENABLED_PATH,
  IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH,
  IS_POST_DESCRIPTION_ENABLED_PATH,
  IS_POST_LIST_FULL_WIDTH_ENABLED_PATH,
  IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH,
  IS_POST_PUBLISH_DATE_ENABLED_PATH,
  IS_POST_TITLE_ENABLED_MOBILE_PATH,
  IS_POST_TITLE_ENABLED_PATH,
  IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH,
  IS_POST_UPDATED_DATE_ENABLED_PATH,
  IS_READING_TIME_ENABLED_MOBILE_PATH,
  IS_READING_TIME_ENABLED_PATH,
  IS_RECENT_POSTS_ENABLED_MOBILE_PATH,
  IS_RECENT_POSTS_ENABLED_PATH,
  IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH,
  IS_SOCIAL_SHARING_ENABLED_PATH,
  IS_VIEW_COUNT_ENABLED_MOBILE_PATH,
  IS_VIEW_COUNT_ENABLED_PATH,
  POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH,
  POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH,
  POST_PAGE_FONT_SIZE_MOBILE_PATH,
  POST_QUOTES_FONT_SIZE_MOBILE_PATH,
  POST_TITLE_FONT_SIZE_MOBILE_PATH,
  SECTION_CATEGORY,
  SECTION_HOMEPAGE,
  IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH,
  IS_AUTHOR_BADGE_ENABLED_MOBILE_PATH,
  IS_AUTHOR_PICTURE_ENABLED_PATH,
  IS_AUTHOR_BADGE_ENABLED_PATH,
  IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH,
  IS_MORE_OPTIONS_MENU_ENABLED_PATH,
} from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { EXPERIMENT_HEADER_DESIGN } from '@wix/communities-blog-experiments';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';
import { isExperimentEnabled } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';
import {
  TEXT_ALIGNMENT_DEFAULT_HOMEPAGE,
  TEXT_ALIGNMENT_DEFAULT_CATEGORY,
} from '@wix/communities-blog-client-common/dist/src/constants/text-alignment';
import { SOCIAL_SHARING_PROVIDERS } from '@wix/communities-blog-client-common/dist/src/constants/social-sharing';
import {
  getLayoutTypeById,
  SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  LAYOUT_NAME_PG_SIDE_BY_SIDE,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import {
  DEFAULT_POST_PAGE_FONT_SIZE_MOBILE,
  DEFAULT_POST_TITLE_FONT_SIZE_MOBILE,
  DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE,
  DEFAULT_POST_HEADER_THREE_SIZE_MOBILE,
  DEFAULT_POST_QUOTES_SIZE_MOBILE,
} from '../../post-page/constants/post-page';
import { getLayoutType } from './layout-selectors';
import { getDefaultFontSize } from '@wix/communities-blog-client-common/dist/src/constants/default-font-sizes';
import { getPresetColor } from '../services/colors';
import { IS_BANNER_ENABLED_DEFAULT } from '@wix/communities-blog-client-common/dist/src/constants/banner';
import {
  settingsKeys,
  getPGLayoutSettingsConstant,
} from '@wix/communities-blog-client-common/dist/src/constants/pro-gallery';
import { getSection } from './section-selectors';

export const getAppSettings = state => state.appSettings;

export const getAppSettingsValue = ({ state, key, fallback }) => get(getAppSettings(state), key, fallback);

const NOT_DEFINED = 'undefined_';
export const isDefinedAppSettingsValue = (state, key) =>
  getAppSettingsValue({ state, key, fallback: NOT_DEFINED }) !== NOT_DEFINED;

const isEnabledAppSettingsValue = (state, key) => getAppSettingsValue({ state, key, fallback: false }) === true;

export const getAppSettingsPresets = state => state.appSettingsPresets;

export const getStyle = state => getAppSettingsValue({ state, key: 'style', fallback: {} });

export const getCustomAppSettings = state => getAppSettingsValue({ state, key: 'custom', fallback: {} });

export const getLastUpdatedDate = state =>
  getAppSettingsValue({ state, key: 'updatedAt', fallback: new Date().getTime() });

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getColorPresets = createDeepEqualSelector([getAppSettings], appSettings => get(appSettings, 'colorPresets', []));

export const getSiteColors = createDeepEqualSelector([getColorPresets], colorPresets =>
  (colorPresets || []).reduce((acc, { reference, value }) => {
    acc[reference] = value;
    return acc;
  }, {}),
);

export const getIsMobileDisplaySettingsEnabled = state =>
  getAppSettingsValue({ state, key: IS_MOBILE_DIPSLAY_SETTINGS_ENABLED_PATH, fallback: false });

const getUseMobileSettings = state => getIsMobile(state) && getIsMobileDisplaySettingsEnabled(state);

const getDisplayToggle = ({ state, key, mobileKey, fallback }) =>
  getAppSettingsValue({
    state,
    key: getUseMobileSettings(state) ? mobileKey : key,
    fallback,
  });

export const getIsCommentCountEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_COMMENT_COUNT_ENABLED_MOBILE_PATH,
    key: IS_COMMENT_COUNT_ENABLED_PATH,
    fallback: true,
  });

export const getIsLikeCountEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_LIKE_COUNT_ENABLED_MOBILE_PATH,
    key: IS_LIKE_COUNT_ENABLED_PATH,
    fallback: true,
  });

export const getIsCategoryLabelsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
    key: IS_CATEGORY_LABELS_ENABLED_PATH,
    fallback: true,
  });

export const getIsMoreButtonEnabled = (state, showMoreButton) => getIsMoreOptionsMenuEnabled(state) && showMoreButton;

export const getIsPostTitleEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_TITLE_ENABLED_MOBILE_PATH,
    key: IS_POST_TITLE_ENABLED_PATH,
    fallback: true,
  });

export const getIsPostDescriptionEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH,
    key: IS_POST_DESCRIPTION_ENABLED_PATH,
    fallback: true,
  });

export const getIsMoreOptionsMenuEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH,
    key: IS_MORE_OPTIONS_MENU_ENABLED_PATH,
    fallback: true,
  });

export const getIsViewCountEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_VIEW_COUNT_ENABLED_MOBILE_PATH,
    key: IS_VIEW_COUNT_ENABLED_PATH,
    fallback: true,
  });

export const getIsAuthorNameEnabled = state => {
  const useMobileSettings = getUseMobileSettings(state);
  return getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_NAME_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_NAME_ENABLED_PATH,
    fallback: useMobileSettings
      ? getAppSettingsValue({ state, key: IS_AUTHOR_NAME_ENABLED_PATH, fallback: true })
      : true,
  });
};

export const getIsAuthorPictureEnabled = (state, fallback = true) =>
  getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_PICTURE_ENABLED_PATH,
    fallback,
  });

export const getIsAuthorBadgeEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_BADGE_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_BADGE_ENABLED_PATH,
    fallback: getUseMobileSettings(state) ? getIsAuthorNameEnabled(state) : true,
  });

export const getAuthorInfoType = state =>
  getDisplayToggle({
    state,
    key: AUTHOR_INFO_TYPE_PATH,
    mobileKey: AUTHOR_INFO_TYPE_MOBILE_PATH,
    fallback: getUseMobileSettings(state)
      ? getAppSettingsValue({ state, key: AUTHOR_INFO_TYPE_PATH, fallback: AUTHOR_INFO_TYPE_NAME_PICTURE })
      : AUTHOR_INFO_TYPE_NAME_PICTURE,
  });

export const getIsPostDateEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_DATE_ENABLED_PATH,
    fallback: true,
  });

export const getIsPostUpdatedDateEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_UPDATED_DATE_ENABLED_PATH,
    fallback: true,
  });

export const getIsPostListFullWidthEnabled = state =>
  getAppSettingsValue({ state, key: IS_POST_LIST_FULL_WIDTH_ENABLED_PATH, fallback: false });

export const getIsFeedDesignPropsMigrated = state =>
  getAppSettingsValue({ state, key: IS_FEED_DESIGN_PROPS_MIGRATED_PATH, fallback: false });

export const getIsPostPublishDateEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_PUBLISH_DATE_ENABLED_PATH,
    fallback: getIsPostDateEnabled(state),
  });

export const getIsReadingTimeEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_READING_TIME_ENABLED_MOBILE_PATH,
    key: IS_READING_TIME_ENABLED_PATH,
    fallback: getIsPostDateEnabled(state),
  });

export const isHeaderDeprecated = state => getAppSettingsValue({ state, key: HEADER_DEPRECTED_PATH, fallback: false });

export const getIsHeaderDesignEnabled = state =>
  isExperimentEnabled(state, EXPERIMENT_HEADER_DESIGN) ||
  isEnabledAppSettingsValue(state, HEADER_SHOW_ON_BLOG_HOMEPAGE_PATH) ||
  isEnabledAppSettingsValue(state, HEADER_SHOW_ON_CATEGORY_PAGE_PATH) ||
  (isHeaderDeprecated(state) !== true &&
    (isDefinedAppSettingsValue(state, HEADER_SHOW_ON_BLOG_HOMEPAGE_PATH) ||
      isDefinedAppSettingsValue(state, HEADER_SHOW_ON_CATEGORY_PAGE_PATH)));

export const getHeaderTextAlignment = (state, section) =>
  getAppSettingsValue({
    state,
    key: getHeaderTextAlignmentPath(section),
    fallback: section === SECTION_HOMEPAGE ? TEXT_ALIGNMENT_DEFAULT_HOMEPAGE : TEXT_ALIGNMENT_DEFAULT_CATEGORY,
  });

export const getCardBorderWidth = state => getAppSettingsValue({ state, key: CARD_BORDER_WIDTH_PATH, fallback: 1 });

export const getAppSettingsNumber = (state, key) =>
  getAppSettingsValue({ state, key: `style.numbers.${key}`, fallback: 1 });

export const getIsBlogMenuEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuCategoryLabelsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuSearchEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_SEARCH_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuLoginButtonEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH,
    fallback: !getIsMemberAreaInstalled(state),
  });

export const getIsRecentPostsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_RECENT_POSTS_ENABLED_MOBILE_PATH,
    key: IS_RECENT_POSTS_ENABLED_PATH,
    fallback: true,
  });

export const getIsCommentsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_COMMENTS_ENABLED_MOBILE_PATH,
    key: IS_COMMENTS_ENABLED_PATH,
    fallback: true,
  });

export const getIsSocialSharingEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH,
    key: IS_SOCIAL_SHARING_ENABLED_PATH,
    fallback: true,
  });

export const getIsSocialSharingProviderEnabled = (state, provider) =>
  getAppSettingsValue({
    state,
    key: getIsSocialSharingProviderEnabledParamPath(provider, getUseMobileSettings(state)),
    fallback: true,
  });

export const getSocialSharingProviders = state =>
  getIsSocialSharingEnabled(state)
    ? filter(SOCIAL_SHARING_PROVIDERS, provider => getIsSocialSharingProviderEnabled(state, provider))
    : [];

export const getPostTitleFontSizeMobile = state =>
  getAppSettingsValue({ state, key: POST_TITLE_FONT_SIZE_MOBILE_PATH, fallback: DEFAULT_POST_TITLE_FONT_SIZE_MOBILE });
export const getPostPageFontSizeMobile = state =>
  getAppSettingsValue({ state, key: POST_PAGE_FONT_SIZE_MOBILE_PATH, fallback: DEFAULT_POST_PAGE_FONT_SIZE_MOBILE });
export const getPostHeaderTwoFontSizeMobile = state =>
  getAppSettingsValue({
    state,
    key: POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE,
  });
export const getPostHeaderThreeFontSizeMobile = state =>
  getAppSettingsValue({
    state,
    key: POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_HEADER_THREE_SIZE_MOBILE,
  });
export const getPostQuotesFontSizeMobile = state =>
  getAppSettingsValue({
    state,
    key: POST_QUOTES_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_QUOTES_SIZE_MOBILE,
  });

const getDesktopFontSize = (state, section, font) =>
  getAppSettingsValue({ state, key: `${getGenericPostFontPath(section, font)}.size` });

export const getFontSize = (state, layoutType, section, contentType) => {
  const stylePath = getPostFontSizesPath(layoutType, section || SECTION_CATEGORY, `${contentType}FontSize`);
  const defaultSize = getDefaultFontSize(layoutType, contentType);
  const desktopFontSize = getIsMobile(state) ? getDesktopFontSize(state, section, `${contentType}Font`) : undefined;
  return get(getAppSettings(state), stylePath, desktopFontSize || defaultSize);
};

export const getPinterestEnabled = state =>
  getAppSettingsValue({
    state,
    key: IS_PINTEREST_ENABLED_PATH,
    fallback: true,
  });

export const getSBSLayoutImagePosition = (state, section) =>
  getAppSettingsValue({
    state,
    key: getSBSLayoutImagePositionParamPath(section),
    fallback: SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  });

export const getIsFeaturedPostBannerEnabled = state =>
  getAppSettingsValue({ state, key: IS_BANNER_ENABLED_PATH, fallback: IS_BANNER_ENABLED_DEFAULT });

export const getIsCreatedWithResponsiveEditor = state => get(getAppSettings(state), 'style.booleans.responsive', false);

const PG_SIDE_BY_SIDE_LAYOUT_SIZE = 1100;

export const getLayoutPostSize = ({ state, section, layoutType, layoutName, hostWidth, rootWidth }) => {
  if (layoutName === LAYOUT_NAME_PG_SIDE_BY_SIDE) {
    const width = isNumber(hostWidth) ? hostWidth : isNumber(rootWidth) ? rootWidth : null;
    if (width !== null && width < PG_SIDE_BY_SIDE_LAYOUT_SIZE) {
      return width;
    }

    return PG_SIDE_BY_SIDE_LAYOUT_SIZE;
  }

  return getAppSettingsValue({
    state,
    key: getLayoutPostSizeParamPath(section, layoutName),
    fallback: getPGLayoutSettingsConstant(layoutType, settingsKeys.DEFAULT_PG_LAYOUT_ITEM_SIZE),
  });
};

const getPGLayoutAppSetting = (paramPathResolver, fallBackSetting) => ({ state, section, layoutType, layoutName }) => {
  section = section || getSection(state);
  layoutType = layoutType || getLayoutType(state, section);
  layoutName = layoutName || getLayoutTypeById(layoutType);

  return getAppSettingsValue({
    state,
    key: paramPathResolver(section, layoutName),
    fallback: getPGLayoutSettingsConstant(layoutType, fallBackSetting),
  });
};

export const getLayoutSpacing = getPGLayoutAppSetting(getLayoutSpacingParamPath, settingsKeys.DEFAULT_PG_LAYOUT_SPACNG);

export const getLayoutImageRatio = getPGLayoutAppSetting(
  getLayoutImageRatioParamPath,
  settingsKeys.DEFAULT_PG_LAYOUT_IMAGE_RATIO,
);

export const getLayoutImageAlignment = getPGLayoutAppSetting(
  getLayoutImageAlignmentParamPath,
  settingsKeys.DEFAULT_PG_IMAGE_ALIGNMENT,
);

export const getLayoutContentHeight = getPGLayoutAppSetting(
  getLayoutContentHeightParamPath,
  settingsKeys.DEFAULT_PG_LAYOUT_INFO_SIZE,
);

export const getLayoutMargins = getPGLayoutAppSetting(
  getLayoutMarginsParamPath,
  settingsKeys.DEFAULT_PG_LAYOUT_MARGINS,
);

export const getLayoutSidesPadding = getPGLayoutAppSetting(
  getLayoutSidesPaddingParamPath,
  settingsKeys.DEFAULT_PG_LAYOUT_SIDES_PADDING,
);

export const getLayoutImageProportions = getPGLayoutAppSetting(
  getLayoutImageProportionsParamPath,
  settingsKeys.DEFAULT_PG_IMAGE_PROPORTIONS,
);

export const getFeedBorderColor = ({ state, section }) =>
  getAppSettingsValue({
    state,
    key: getFeedBorderColorPath(section),
    fallback: { value: getPresetColor(getAppSettings(state), 'color-2') },
  });
