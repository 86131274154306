import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {
  isLayoutPGSideBySide,
  isLayoutProGallery,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import withLayoutProps from '../../hoc/with-layout-props';
import { getIsAppBundle, getIsPostPageBundle } from '../../store/app-config/app-config-selectors';
import { getIsCreatedWithResponsiveEditor, getLayoutMargins } from '../../selectors/app-settings-selectors';
import { getLayoutType } from '../../selectors/layout-selectors';
import styles from './desktop-header.scss';
import { connect } from '../runtime-context';

const renderMenu = menu => {
  return menu && <div className={styles.menu}>{menu}</div>;
};

const DesktopHeader = ({
  left,
  right,
  menu,
  className,
  createdWithResponsiveEditor,
  isAppBundle,
  isPostPageBundle,
  layoutMargins,
  layoutType,
}) => {
  const containerClassName = classNames(styles.container, className, 'blog-header-background-color');
  const wrapperClassName = classNames(
    styles.header,
    !isLayoutProGallery(layoutType) && styles.withMaxWidth,
    (isAppBundle || (isPostPageBundle && createdWithResponsiveEditor)) && styles.withPadding,
  );

  const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);

  // todo - later layoutPGSideBySide should also have side margin when the layout itself will have that
  const containerStyles =
    createdWithResponsiveEditor || layoutPGSideBySide || !layoutMargins
      ? {}
      : { paddingRight: layoutMargins, paddingLeft: layoutMargins };

  return (
    <div style={containerStyles} className={containerClassName} data-hook="blog-desktop-header-container">
      <div className={wrapperClassName}>
        {left}
        {!createdWithResponsiveEditor && right}
      </div>
      {renderMenu(menu)}
    </div>
  );
};

DesktopHeader.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  className: PropTypes.string,
  createdWithResponsiveEditor: PropTypes.bool.isRequired,
  isAppBundle: PropTypes.bool.isRequired,
  isPostPageBundle: PropTypes.bool.isRequired,
  layoutMargins: PropTypes.number,
  layoutType: PropTypes.number.isRequired,
};

const mapRuntimeToProps = state => ({
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  isAppBundle: getIsAppBundle(state),
  isPostPageBundle: getIsPostPageBundle(state),
  layoutMargins: getLayoutMargins({ state }),
  layoutType: getLayoutType(state),
});

export default flowRight(connect(mapRuntimeToProps), withLayoutProps())(DesktopHeader);
